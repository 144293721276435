import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import PageLayoutHeader from '../../pages/common/Headers/PageLayoutHeader';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import { getRequester } from '../../helpers/helpers';
import USERS from '../../common/data/userDummyData';
import Icon from '../../components/icon/Icon';
import Input from '../../components/bootstrap/forms/Input';
import UserModule from '../../modules/ninda/UserModule';

const Profile = () => {
	const { person_name, photo, email, username } = getRequester();
	const [emailUser, setEmailUser] = useState(email ?? null);
	const [isReadOnly, setIsReadOnly] = useState('plaintext');

	useEffect(() => {}, [emailUser]);

	const handleSubmit = () => {
		UserModule.updateEmail({ email: emailUser })
			.then(() => {
				Swal.fire('Information', 'Data has updated successfully!', 'success');
			})
			.catch((err) => {
				Swal.fire('Warning', err, 'error');
			});
		setIsReadOnly('plaintext');
	};

	return (
		<PageWrapper title='Profile'>
			<PageLayoutHeader />
			<Page container='fluid' className='mx-5 px-5'>
				<div className='d-flex align-items-center my-2'>
					<h1 className='fw-bolder'>{person_name}</h1>
				</div>
				<div className='row my-2'>
					<div className='col-4'>
						<Card shadow='3d'>
							<CardBody strech>
								<div>
									<div className='d-flex flex-row-reverse m-0'>
										{isReadOnly === 'plaintext' ? (
											<Button
												icon='Edit'
												color='info'
												type='button'
												isLink
												size='lg'
												onClick={() => setIsReadOnly(false)}
											/>
										) : (
											<Button
												icon='Save'
												color='info'
												type='button'
												isLink
												size='lg'
												onClick={handleSubmit}
											/>
										)}
									</div>
									<div className='d-flex justify-content-center my-3'>
										<img
											srcSet={
												photo &&
												Object.keys(photo).length &&
												photo.error === undefined
													? `data:${photo?.mimetype};base64,${photo?.file}`
													: USERS.JOHN.srcSet
											}
											src={
												photo &&
												Object.keys(photo).length &&
												photo.error === undefined
													? `data:${photo?.mimetype};base64,${photo?.file}`
													: USERS.JOHN.src
											}
											alt='Avatar'
											className='rounded-circle'
											width={128}
											height={128}
										/>
									</div>
									<div className='mt-5'>
										<div className='row align-items-center my-3'>
											<div className='col-2 d-flex justify-content-center mx-0'>
												<Icon icon='Email' color='info' size='3x' />
											</div>
											<div className='col-10 text-left mx-0 ps-0'>
												<div>
													<Input
														placeholder='Your Email'
														type='email'
														id='email'
														name='Email'
														className='fw-bold fs-5'
														size='sm'
														readOnly={isReadOnly}
														value={emailUser || ''}
														onChange={(e) =>
															setEmailUser(e.target.value)
														}
													/>
												</div>
												<div>Email Address</div>
											</div>
										</div>
										<div className='row align-items-center my-3'>
											<div className='col-2 d-flex justify-content-center mx-0'>
												<Icon icon='Tag' color='info' size='3x' />
											</div>
											<div className='col-10 text-left mx-0 ps-0'>
												<div>
													<h4 className='fw-bold'>{username}</h4>
												</div>
												<div>Username</div>
											</div>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Profile;
