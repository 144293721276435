import userService from "../../services/ninda/user.service"

const updateEmail = (payload) => {
    return userService.updateEmail(payload).then(
        (response) => {
            return Promise.resolve(response.data, response.data.message);
        },
        (error) => {
            const message = 
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            return Promise.reject(message)
        }
    )
}

export default { updateEmail }