import React from 'react';
import classNames from 'classnames';
import Header from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';

const PageLayoutHeader = () => {
	return (
		<Header>
			<div className={classNames('header-left', 'col-md')}>
				<div className='d-flex flex-col'>
					{process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'production' && (
						<div className='blink'>{process.env.REACT_APP_ENV.toUpperCase()}</div>
					)}
				</div>
			</div>
			<CommonHeaderRight />
		</Header>
	);
};

export default PageLayoutHeader;
