import React, { lazy } from 'react';

// define manual import for preventing hook restriction
const DUMMY = {
	CRUD: lazy(() => import('../views/tyo/CRUD')),
	Encryption: lazy(() => import('../views/tyo/Encryption')),
};

const Master = {
	Master: lazy(() => import('../views/sodiq/master/index')),
	Customer: lazy(() => import('../views/sodiq/customer/index')),
	Viewer: lazy(() => import('../views/sodiq/viewer/index'))
};

const contents = [
	{
		path: null,
		element: <DUMMY.CRUD />,
		index: 'CRUD',
		exact: true,
	},

	{
		path: null,
		element: <DUMMY.Encryption />,
		index: 'Encryption',
	},
	{
		path: null,
		element: <Master.Master />,
		index: 'MasterCalculator',
		exact: true,
	},
	{
		path: null,
		element: <Master.Customer />,
		index: 'CustomerCalculator',
		exact: true,
	},
	{
		path: null,
		element: <Master.Viewer />,
		index: 'ViewerCalculator',
		exact: true,
	}

];
export default contents;
