import axios from "axios"
import authHeader from "../auth-header"
import { getRequester } from "../../helpers/helpers";

const API_URL = process.env.REACT_APP_API;

const updateEmail = async (email) => {
    const { username } = getRequester()
    return axios
        .put(`${API_URL}user/email`, { username, ...email }, { headers: await authHeader() })
        .then(async (response) => {
            return response
        })
}

export default {
    updateEmail
}